import { useEffect, useState } from "react";
import MainCard from "components/MainCard";
import { Table } from "antd";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { dispatch } from "store/index";
import { GetAllUsers } from "store/action/index";
import GetColumnSearchProps from "helper/searchHelper";

const AllUsers = ({ allUsersData }) => {


  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    dispatch(GetAllUsers({ setLoading }));
  }, []);

  useEffect(() => {
    // Map through data and add key using id field
    const formattedData = allUsersData?.map(blog => ({
      ...blog,
      key: blog.id, // Use id as the key
    })) ?? [];

    setDataSource(formattedData);
  }, [allUsersData]);


  const columns = [
  
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...GetColumnSearchProps("name"),
      render: (text, record) => {
        return (
          <div >
<p target="_blank">{text ? text : record?.first_name ? (record?.first_name || '') + ' ' + (record?.last_name || '') : 'Name not Found'}</p>
              </div>
        )
      },
    },
    
    {
        title: "Email",
        dataIndex: "email",
        key: "email",
        ...GetColumnSearchProps("email"),
        render: (text, record) => {
          return (
            <div >
  <p target="_blank">{text ? text : 'Email not Found'}</p>
                </div>
          )
        },
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
        ...GetColumnSearchProps("phone"),
        render: (text, record) => {
          return (
            <div >
  <p target="_blank">{text ? (text || '') : 'Phone not Found'}</p>
                </div>
          )
        },
      }
  ];

 

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          height: "75px",
          borderRadius: "12px",
          marginBottom: "20px",
          padding: "0 16px",
          backgroundColor: "#fff",
          border: '1px solid',
          borderColor: 'rgba(228,162,49,0.8)',
        }}
      >
        <Grid container alignItems="center" justifyContent="flex-start">
          <Grid item>
            <span
              style={{
                fontSize: "22px",
                fontWeight: "600",
              }}
            >
              All Users
            </span>
          </Grid>
        </Grid>
    
      </div>
      <MainCard>
        <Table
          style={{ overflowX: "auto" }}
          pagination={false}
          dataSource={dataSource} columns={columns} loading={loading}
        />
      </MainCard>
    </>
  );
};

const mapStateToProps = (state) => ({
    allUsersData: state?.GetAllLeads?.allUsersData,
});

export default connect(mapStateToProps)(AllUsers);
