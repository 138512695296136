// ========================================= property management ===================================

export const CREATE_ALL_PROPERTY = "CREATE_ALL_PROPERTY";
export const GET_ALL_PROPERTY = "GET_ALL_PROPERTY";
export const GET_ALL_ACTIVE_TAX = "GET_ALL_ACTIVE_TAX";

export const GET_PROPERTY_PREMESIS = "GET_PROPERTY_PREMESIS";
export const GET_ALL_ACTIVE_PROPERTY = "GET_ALL_ACTIVE_PROPERTY";
export const UPDATE_PROPERTY = "UPDATE_PROPERTY";
export const UPDATE_PROPERTY_STATUS = "UPDATE_PROPERTY_STATUS";

export const GET_ALL_PROPERTY_TYPE = "GET_ALL_PROPERTY_TYPE";
export const GET_ALL_ACTIVE_PROPERTY_TYPE = "GET_ALL_ACTIVE_PROPERTY_TYPE";
export const CREATE_PROPERTY_TYPE = "CREATE_PROPERTY_TYPE";
export const UPDATE_PROPERTY_TYPE = "UPDATE_PROPERTY_TYPE";
export const UPDATE_PROPERTY_STATUS_TYPE = "UPDATE_PROPERTY_STATUS_TYPE";
export const GET_ALL_DASHBOARD = "GET_ALL_DASHBOARD";
export const GET_ALL_NOTIFICATION = "GET_ALL_NOTIFICATION";
export const PREMISES_FILTERS = "PREMISES_FILTERS";
export const TENANT_DELETE = "TENANT_DELETE";
export const PROPERTY_DLETE = "PROPERTY_DLETE";
export const PREMISES_DELETE = "PREMISES_DELETE";

// export const GET_ALL_AMENITIES = "GET_ALL_AMENITIES";
export const GET_ALL_ACTIVE_AMENITIES = "GET_ALL_ACTIVE_AMENITIES";
export const CREATE_AMENITIES = "CREATE_AMENITIES";
export const UPDATE_AMENITIES = "UPDATE_AMENITIES";
export const UPDATE_AMENITIES_STATUS = "UPDATE_AMENITIES_STATUS";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";

export const GET_ALL_FLAT_SIZE = "GET_ALL_FLAT_SIZE";
export const GET_ALL_ACTIVE_FLAT_SIZE = "GET_ALL_ACTIVE_FLAT_SIZE";
export const CREATE_FLAT_SIZE = "CREATE_FLAT_SIZE";
export const UPDATE_FLAT_SIZE = "UPDATE_FLAT_SIZE";
export const UPDATE_FLAT_SIZE_STATUS = "UPDATE_FLAT_SIZE_STATUS";

export const GET_ALL_FLAT = "GET_ALL_FLAT";
export const GET_ALL_ACTIVE_FLAT = "GET_ALL_ACTIVE_FLAT";
export const CREATE_FLAT = "CREATE_FLAT";
export const UPDATE_FLAT = "UPDATE_FLAT";
export const UPDATE_FLAT_STATUS = "UPDATE_FLAT_STATUS";

export const GET_ALL_TAXES = "GET_ALL_TAXES";
export const CREATE_TAXES = "CREATE_TAXES";
export const UPDATE_TAXES = "UPDATE_TAXES";
export const UPDATE_TAXES_STATUS = "UPDATE_TAXES_STATUS";
export const CLIENT_ALL_VIEW = "CLIENT_ALL_VIEW";
export const CLIENT_ALL_DETAILS = "CLIENT_ALL_DETAILS,";
export const CREATE_CLIENTS = "CREATE_CLIENTS";
export const UPDATE_CLIENTS = "UPDATE_CLIENTS";
export const CREATE_MAINTENANCE_TYPE = "CREATE_MAINTENANCE_TYPE";

export const GET_ALL_MANAGEMENT = "GET_ALL_MANAGEMENT";
export const UPDATE_MAINTENANCE_STATUS = "UPDATE_MAINTENANCE_STATUS";
export const CREATE_ADD_MAINTENANCE = "CREATE_ADD_MAINTENANCE";
export const UPDATE_EDIT_MAINTENANCE = "UPDATE_EDIT_MAINTENANCE";
export const UPDATE_MAINTENANCE_TYPE_STATUS = "UPDATE_MAINTENANCE_TYPE_STATUS";
export const EDIT_MAINTENANCE_TYPE_STATUS = "EDIT_MAINTENANCE_TYPE_STATUS";
export const ALL_MAINTENANCE = "ALL_MAINTENANCE";
export const ALL_ACTIVE_MAINTENANCE_TYPE = "ALL_ACTIVE_MAINTENANCE_TYPE";

export const ALL_ASSIGN_PERMISSIONS = "ALL_ASSIGN_PERMISSIONS";
export const ALL_ASSIGN_PREMISES_DETAILS = "ALL_ASSIGN_PERMISSIONS";
export const CREATE_ASSIGN_PERMISSION = "CREATE_ASSIGN_PERMISSION";

export const PAYMENT_UNIQUE_ID = "PAYMENT_UNIQUE_ID";
export const CREATE_PAYMENT = "CREATE_PAYMENT";
export const GET_PAYMENT = "GET_PAYMENT";

export const CREATE_ADD_USERS = "CREATE_ADD_USERS";
export const EDIT_UPDATE_USERS = "EDIT_UPDATE_USERS";
export const GET_ALL_USERS = "GET_ALL_USERS";

export const ALL_NOTIFICATION = "ALL_NOTIFICATION";

export const ALL_REPORTS = "ALL_REPORTS";
export const REPORT_BY_MONTH = "REPORT_BY_MONTH";
export const REPORT_BY_CLIENT = "REPORT_BY_CLIENT";
export const REPORT_BY_PAYMENT = "REPORT_BY_PAYMENT";

export const REPORTS_BY_PROPERTY = "REPORTS_BY_PROPERTY";
export const REPORTS_BY_FLATS_SHOPS = "REPORTS_BY_FLATS_SHOPS";

// ========================================= property management ===================================

export const FETCH_LOGIN_DATA = "FETCH_LOGIN_DATA";
export const FETCH_SIGNUP_DATA = "FETCH_SIGNUP_DATA";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const REGISTER_BRAND_OWNER = "REGISTER_BRAND_OWNER";
export const REGISTER_CATEGORY_STORE = "REGISTER_CATEGORY_STORE";
export const CREATE_ROLE = "CREATE_ROLE";
export const UPDATE_STATUS_CODE = "UPDATE_STATUS_CODE";
export const UPDATE_STATUS_USERS = "UPDATE_STATUS_USERS";
export const UPDATE_STATUS_BRAND = "UPDATE_STATUS_BRAND";
export const VIEW_USERS = "VIEW_USERS";
export const VIEW_PRODUCTS = "VIEW_PRODUCTS";
// export const VIEW_REPORT_PROBLEM = 'VIEW_REPORT_PROBLEM';
// export const GET_SERIAL_FOR_BATCH = 'GET_SERIAL_FOR_BATCH';
export const VIEW_ACTIVE_PRODUCTS = "VIEW_ACTIVE_PRODUCTS";
export const VIEW_ROLES = "VIEW_ROLES";
export const ASSIGN_PERMISSIONS = "ASSIGN_PERMISSIONS";
// export const UPDATE_RATE_LIMIT = 'UPDATE_RATE_LIMIT';
// export const GET_BATCH_PRODUCT = 'GET_BATCH_PRODUCT'
export const GETALL_PERMISSION = "GETALL_PERMISSION";
export const ROLE_PERMISSIONS = "ROLE_PERMISSIONS";
export const EDIT_USER = "EDIT_USER";
export const ALL_BRAND = "ALL_BRAND";
export const MAP_DATA = "MAP_DATA";

// export const ALL_FAQS = 'ALL_FAQS'
// export const ALL_TERMS = 'ALL_TERMS'
// export const EDIT_COLOR_SHIFT = 'EDIT_COLOR_SHIFT'
// export const EDIT_SMSREPLY = 'EDIT_SMSREPLY'
export const GETALL_CATEGEORY = "GETALL_CATEGEORY";
// export const GET_ALL_COLOR_SHIFT = 'GET_ALL_COLOR_SHIFT'
// export const ALL_SMS_REPLY = 'ALL_SMS_REPLY'
// export const STATUS_UPDATE_SMSREPLY = 'STATUS_UPDATE_SMSREPLY'
export const BRAND_ONE_DETAIL = "BRAND_ONE_DETAIL";
export const BRAND_EDIT = "BRAND_EDIT";
// export const EDIT_CATEGORY = 'EDIT_CATEGORY';
// export const ALL_SUB_CATEGORIES = 'ALL_SUB_CATEGORIES';
export const SUB_CATEGORY_SATUS_UPDATE = "SUB_CATEGORY_SATUS_UPDATE";
export const STATUS_UPDATE_CATEGORY = "STATUS_UPDATE_CATEGORY";
// export const CREATE_SUB_CATEGORY = 'CREATE_SUB_CATEGORY';
export const EDIT_SUB_CATEGORY = "EDIT_SUB_CATEGORY";
export const ALL_COMPANY = "ALL_COMPANY";
// export const POST_SMS_REPLY = 'POST_SMS_REPLY'
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
// export const POST_UPDATE_FAQS = 'POST_UPDATE_FAQS'
// export const POST_UPDATE_TERMS = 'POST_UPDATE_TERMS'
export const ALL_BATCHES = "ALL_BATCHES";
export const ALL_BATCHES_COMPANY = "ALL_BATCHES_COMPANY";
// export const CREATE_COMPANY = 'CREATE_COMPANY';
// export const STATUS_UPDATE_COMPANY = 'STATUS_UPDATE_COMPANY';
export const COMPANY_DETAIL_ONE_USER = "COMPANY_DETAIL_ONE_USER";
export const All_COUNTS = "All_COUNTS";
// export const All_COUNTS = 'GET_ALL_COUNTS';
export const All_RANDOM_CODE = "GET_ALL_RANDOM_CODE";
// export const REQUEST_RANDOM_CODE = 'REQUEST_RANDOM_CODE';

// export const ALL_SMS_LOGS = 'GET_ALL_SMS_LOGS';
// export const GET_CSV = 'GET_CSV';

// export const ALL_SMS_REPORT = 'GET_ALL_SMS_REPORT';
export const ALL_QRCODE_REPORT = "GET_ALL_QRCODE_REPORT";
export const EXPORT_CSV = "EXPORT_CSV";
// export const EXPORT_QR_CSV = 'EXPORT_QR_CSV';
export const EXPORT_CSV_COMPANY = "EXPORT_CSV_COMPANY";
export const EXPORT_CSV_REPORT_PROBLEM = "EXPORT_CSV_REPORT_PROBLEM";
export const EXPORT_CSV_CATEGORYIES = "EXPORT_CSV_CATEGORYIES";
export const EXPORT_CSV_RANDOM_CODE = "EXPORT_CSV_RANDOM_CODE";
// export const PROGRESSIVE_CODES = 'PROGRESSIVE_CODES';

export const ALL_CERTIFICATIONS = "ALL_CERTIFICATIONS";

export const CREATE_ALL_CERTIFICATIONS = "CREATE_ALL_CERTIFICATIONS";
export const UPDATE_ALL_CERTIFICATIONS = "UPDATE_ALL_CERTIFICATIONS";
export const CREATE_ALL_FEEDBACKS = "CREATE_ALL_FEEDBACKS";
export const GET_ALL_FEEDBACKS = "GET_ALL_FEEDBACKS";
export const CERTIFICATE_STATUS = "CERTIFICATE_STATUS";
export const CONTACT_US_CREATE = "CONTACT_US_CREATE";
export const CONTACT_US_GET = "CONTACT_US_GET";
export const CONTACT_US_DELETE = "CONTACT_US_DELETE";
export const ALL_BLOGS_GET = "ALL_BLOGS_GET";
export const ALL_LEADS_GET = "ALL_LEADS_GET";

export const ALL_BLOGS_GET_ID = "ALL_BLOGS_GET_ID";
export const ALL_BLOGS_CREATE = "ALL_BLOGS_CREATE";
export const ALL_BLOGS_UPDATE = "ALL_BLOGS_UPDATE";
export const ALL_PRECONSTRUCTION_GET = "ALL_PRECONSTRUCTION_GET";
export const ALL_CUSTOM_LISTING_GET = "ALL_CUSTOM_LISTING_GET";
export const ALL_APPOINTMENTS_GET = "ALL_APPOINTMENTS_GET";
export const ALL_CONTACTUS_STATUS_UPDATE = "ALL_CONTACTUS_STATUS_UPDATE";


















