import { ALL_LEADS_GET, GET_ALL_USERS } from "../../constant/ActionType";

export default function GetAllLeads(
  state = {
    allLeadsData: [],
    allUsersData: [],
  },
  action
) {
  switch (action.type) {
    case ALL_LEADS_GET:
      return {
        ...state,
        allLeadsData: action?.data?.data,
      };
    case GET_ALL_USERS:
      return {
        ...state,
        allUsersData: action?.data?.data,
      };

    default:
  }

  return state;
}