import { People } from 'iconsax-react';
import { UserOctagon } from 'iconsax-react';

// icons
const icons = {
    navigation: People,
    CalendarTick: UserOctagon,
};


const User = {
      id: "User",
      title: "User",
      icon: icons.navigation,
      type: "group",
      children: [
        // {
        //       id: "all-Members",
        //       title: "Members",
        //       type: "item",
        //       url: "/members/all-members",
        //       icon: icons.navigation,
        //       breadcrumbs: true,
        //     },
        {
              id: "users_management",
              title: "Users Management",
              type: "item",
              url: "/users_management/users_management",
              icon: icons.CalendarTick,
              breadcrumbs: true,
            },
      ],
    };

export default User;
